import React, { FC } from 'react'
import NextHead from 'next/head'

declare global {
  interface Window {
    Didomi: {
      preferences: {
        show: () => void
      }
      getUserStatusForVendor: (vendorName: string) => boolean
      getUserStatusForVendorAndLinkedPurposes: (vendorName: string) => boolean
    }
  }
}

interface Props {
  locale: string
}

const Didomi: FC<Props> = ({ locale }) => {
  const DIDOMI_APIKEY = process.env.NEXT_PUBLIC_DIDOMI_APIKEY || ''

  if (!DIDOMI_APIKEY?.length || !locale?.length) {
    return null
  }

  const didomiScript = `
  window.gdprAppliesGlobally=true;(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,o,r,f,s){function e(e,t,n,i){if(typeof n!=="function"){return}if(!window[o]){window[o]=[]}var a=false;if(s){a=s(e,i,n)}if(!a){window[o].push({command:e,version:t,callback:n,parameter:i})}}e.stub=true;e.stubVersion=2;function t(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.version,function(e,t){var n={};n[f]={returnValue:e,success:t,callId:o.callId};if(i.source){i.source.postMessage(a?JSON.stringify(n):n,"*")}},o.parameter)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");i("__tcfapiLocator")})();</script><script type="text/javascript">(function(){(function(e){var r=document.createElement("link");r.rel="preconnect";r.as="script";var t=document.createElement("link");t.rel="dns-prefetch";t.as="script";var n=document.createElement("script");n.id="spcloader";n.type="text/javascript";n["async"]=true;n.charset="utf-8";var o="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;if(window.didomiConfig&&window.didomiConfig.user){var i=window.didomiConfig.user;var a=i.country;var c=i.region;if(a){o=o+"&country="+a;if(c){o=o+"&region="+c}}}r.href="https://sdk.privacy-center.org/";t.href="https://sdk.privacy-center.org/";n.src=o;var d=document.getElementsByTagName("script")[0];d.parentNode.insertBefore(r,d);d.parentNode.insertBefore(t,d);d.parentNode.insertBefore(n,d)})("${DIDOMI_APIKEY}")})();
  `

  const didomiConfig = `
  window.didomiConfig = {
      languages: {
          enabled: ['${locale}'],
          default: '${locale}'
      }
  };
  `

  return (
    <NextHead>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: didomiConfig }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: didomiScript }}
      />
    </NextHead>
  )
}

export default Didomi
